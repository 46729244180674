html, body {
  height: 100%; }

.footerContainer, .footerDisclaimer {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem; }

@media (min-width: 992px) {
  .footerContainer, .footerDisclaimer {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; } }

.footerDisclaimerLogo, .footerDisclaimerLogoContainer {
  margin: 0 auto 2.4rem; }

@media (min-width: 992px) {
  .footerDisclaimerLogo, .footerDisclaimerLogoContainer {
    margin-bottom: 1rem; } }

@media (min-width: 992px) {
  .footerContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    max-width: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: calc(99.9% * (-1/14 * -1) - (20px - 20px * (-1/14 * -1)) + 20px);
    padding-right: calc(99.9% * (-1/14 * -1) - (20px - 20px * (-1/14 * -1)) + 20px);
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap; } }

@media (min-width: 992px) and (min-width: 768px) {
  .footerContainer {
    padding-left: calc(99.9% * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px);
    padding-right: calc(99.9% * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px); } }

@media (min-width: 992px) and (min-width: 1280px) {
  .footerContainer {
    padding-left: calc(1280px * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px);
    padding-right: calc(1280px * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px); } }

.footerDisclaimer {
  overflow: hidden;
  background-color: #1b1e20;
  font-size: 1.2rem; }

.footerDisclaimerLogoWrapper {
  margin: 0 auto; }

.footerDisclaimerLogoContainer {
  background-image: url([object Module]);
  width: 102px;
  height: 28px;
  background-size: cover; }

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .footerDisclaimerLogoContainer {
    background-image: url([object Module]); } }

@media (-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 2.5dppx) {
  .footerDisclaimerLogoContainer {
    background-image: url([object Module]); } }

@media (-webkit-min-device-pixel-ratio: 3.5), (min-resolution: 3.5dppx) {
  .footerDisclaimerLogoContainer {
    background-image: url([object Module]); } }

.footerDisclaimerText {
  width: 100%;
  text-align: center;
  color: #999; }

.footerDisclaimer p {
  margin-bottom: .2rem;
  width: 100%;
  text-align: center; }

.footerDisclaimer p:last-child {
  margin-bottom: 0; }

@media (min-width: 768px) {
  .footerDisclaimer p {
    margin-bottom: .4rem; }
  .footerDisclaimer p:not(:first-of-type) {
    display: inline;
    width: auto; } }

.lostContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: calc(99.9% * (-1/14 * -1) - (20px - 20px * (-1/14 * -1)) + 20px);
  padding-right: calc(99.9% * (-1/14 * -1) - (20px - 20px * (-1/14 * -1)) + 20px); }

@media (min-width: 768px) {
  .lostContainer {
    padding-left: calc(99.9% * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px);
    padding-right: calc(99.9% * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px); } }

@media (min-width: 1280px) {
  .lostContainer {
    padding-left: calc(1280px * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px);
    padding-right: calc(1280px * (-1/26 * -1) - (20px - 20px * (-1/26 * -1)) + 20px); } }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

a {
  background-color: transparent; }

a:active, a:hover {
  outline: 0; }

b, strong {
  font-weight: 700; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

*, :after, :before {
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  background-color: #fff;
  font-size: 1.4rem;
  line-height: 1.42857;
  font-family: UOLText,Arial,sans-serif;
  color: #666;
  min-width: 320px; }

img {
  vertical-align: middle; }

button, input, select, textarea {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit; }

a {
  color: #1082be;
  text-decoration: none; }

a:focus, a:hover {
  text-decoration: underline; }
